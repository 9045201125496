/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-content{
    --padding-start: var(--ion-padding, 16px);
      --padding-end: var(--ion-padding, 16px);
      --padding-top: var(--ion-padding, 16px);
      --padding-bottom: var(--ion-padding, 16px);
      padding-left: var(--ion-padding, 16px);
      padding-right: var(--ion-padding, 16px);
      padding-top: var(--ion-padding, 16px);
      padding-bottom: var(--ion-padding, 16px);
    }

    ion-input {
        color: white !important; /* input text color to primary */
    }

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
   white-space: normal;
}
.alert-tappable.alert-radio {
   height: auto;
   contain: content;
}

.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
   white-space: normal;
}
.alert-tappable.alert-checkbox {
   height: auto;
   contain: content;
}
.spinner {
   --spinner-color: #a81f21;
 }

 .action-sheet {
   --color: #a81f21;
   --button-color: #545454;
   --button-color-activated: #a81f21;
   --button-color-focused: #a81f21;
   --button-color-hover: #a81f21;
   --button-color-selected: #a81f21;
}

.action-sheet .action-sheet-button {
   font-size: 0.8rem;
   height: 60px;
 }